import React from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { ConnectedRouter } from "react-router-redux"
import App from './App';
import history from './history';
import configureStore from './store'
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';
// import Cookies from 'universal-cookie';
const store = configureStore(history)

const client_name = Cookies.get("client") || localStorage.getItem('client')

// const cookies = new Cookies();
// const client_name = cookies.get("client")

class Root extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tagsLoaded: false
    }
  }

  handleScriptInject = (addedTags) => {

    if (Object.keys(addedTags).length != 0) {
      const linkTags = addedTags.linkTags
      const linkTag = linkTags[0];
      linkTag.onload = this.handleLoad()
    }

  }

  handleLoad = () => {
    this.setState({
      tagsLoaded: true
    })
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <Helmet onChangeClientState={(newState, addedTags) => this.handleScriptInject(addedTags)}>
              <meta charSet="utf-8" />
              <link
                type="text/css"
                rel="stylesheet"
                href="vendors/font-awesome/css/font-awesome.min.css"
              />
              <link
                type="text/css"
                rel="stylesheet"
                href="vendors/jquery-ui-1.10.4.custom/css/ui-lightness/jquery-ui-1.10.4.custom.min.css"
              />
              <link
                type="text/css"
                rel="stylesheet"
                href="purepro/css/bootstrap.css"
              />

              < link
                type="text/css"
                rel="stylesheet"
                href={`${client_name}/css/yellow-blue.css`}
              />
            </Helmet>
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="top-center"
              transitionIn="bounceInDown"
              transitionOut="bounceOutUp"
              progressBar
              closeOnToastrClick />
            {this.state.tagsLoaded && <App />}
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default Root;
