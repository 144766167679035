import * as billing from '../actions/billing.js'
import _ from 'lodash';

const initialState = {
  isLoading: false,
  errors: {},
  message: null,
  address: undefined,
  states: [],
  orderDetail: null,
  orderToken: null,
}

export default (state=initialState, action) => {
  switch(action.type) {
    case billing.BILLING_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        address: action.payload.data,        
        errors: {}
      }
    case billing.BILLING_FAILURE:
      return {
        ...state,
        isLoading: false,
        address: undefined,
        errors: action.payload.response
      }
    case billing.STATE_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        states: action.payload.states,        
        errors: {}
      }
    case billing.STATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        states: [],
        errors: action.payload.response
      }
    case billing.ORDERDETAIL_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        orderDetail: action.payload,        
        orderToken: action.payload.token,
        errors: {}
      }
    case billing.ORDERDETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        orderDetail: null,
        orderToken: null,
        errors: action.payload.response
      }
    case billing.ORDERSTATE_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        orderDetail: action.payload,        
        orderToken: action.payload.token,        
        errors: {}
      }
    case billing.ORDERSTATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        orderDetail: null,
        orderToken: null,
        errors: action.payload.response.errors
      }
    case 'CLOSE_ERROR':
      return {...state, message: null, errors:{}, address: undefined}
    default:
      return state
    }
}