export const FILTER_BASED_SUPPLEMENTS = "FILTER_BASED_SUPPLEMENTS";
export const ALL_TAG_LIST = "ALL_TAG_LIST";
export const VERIFY_APP_TOKEN = "VERIFY_APP_TOKEN";
export const SELECTED_SUPPLEMENTS = "SELECTED_SUPPLEMENTS";
export const RECOMMENDED_LIST = "RECOMMENDED_LIST";
export const FETCH_MORE_RECOMMENDED_LIST = "FETCH_MORE_RECOMMENDED_LIST";
export const ADD_MANUALL = "ADD_MANUALL";
export const SELECT_ALL = "SELECT_ALL";
export const UPDATE_SCRIPT_SUCCESS = "UPDATE_SCRIPT_SUCCESS";
export const UPDATE_SCRIPT_INIT = "UPDATE_SCRIPT_INIT"
export const UPDATE_SCRIPT_FAILURE = "UPDATE_SCRIPT_FAILURE"
export const SET_PRACTITION_FLOW = "SET_PRACTITION_FLOW";
export const FROM_RECOMMEND = "FROM_RECOMMEND";
export const GET_SELECT_RECOMMED_IDS = "GET_SELECT_RECOMMED_IDS";
export const CLEAR_RECOMMEDED = "CLEAR_RECOMMEDED";
export const CLEAR_CATALOG = "CLEAR_CATALOG";
export const CLEAR_ALTERNATIVE = "CLEAR_ALTERNATIVE";
export const STORE_SMART_RESULT = "STORE_SMART_RESULT";
export const VARIANT_FROM_PRODUCTCODE = "VARIANT_FROM_PRODUCTCODE";
export const INVENTORY_CHECK = "INVENTORY_CHECK";
export const SHOW_FILTER_TAG = "SHOW_FILTER_TAG";
export const UPDATE_FILTER_CHANGE = "UPDATE_FILTER_CHANGE";
export const GET_SUBSTITUTION = "GET_SUBSTITUTION";
export const GET_REFUND_OPTION = "GET_REFUND_OPTION"
export const CLEAR_SHIPPING_LIST = "CLEAR_SHIPPING_LIST"
export const SAVE_SUPPLEMENTS = "SAVE_SUPPLEMENTS"
export const GET_TOP_SELLING_VARIANTS_REQUEST = "GET_TOP_SELLING_VARIANTS_REQUEST"
export const GET_TOP_SELLING_VARIANTS_SUCCESS = "GET_TOP_SELLING_VARIANTS_SUCCESS"
export const GET_TOP_SELLING_VARIANTS_FAILURE = "GET_TOP_SELLING_VARIANTS_FAILURE"

/* catalog*/
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_TAGS = "GET_TAGS";
export const FETCH_MORE_PRODUCTS = "FETCH_MORE_PRODUCTS";
export const SEARCH_BY_NAME = "SEARCH_BY_NAME";
export const SEARCH_BY_TAG = "SEARCH_BY_TAG";
/* auth */
export const RIALTO_AUTH_START = "RIALTO_AUTH_START";
export const RIALTO_AUTH_SUCCESS = "RIALTO_AUTH_SUCCESS";
export const RIALTO_AUTH_ERROR = "RIALTO_AUTH_ERROR";
export const BROW_CATAL_STATE_CHANGE = "BROW_CATAL_STATE_CHANGE"

/* script*/

export const CREATE_SCRIPT = "CREATE_SCRIPT";
export const PRACTITIONER_SCRIPTS = "PRACTITIONER_SCRIPTS";
export const GET_DOSAGE = "GET_DOSAGE";
export const SCRIPT_DETAILS = "SCRIPT_DETAILS";
export const PATIENT_SCRIPTS = "PATIENT_SCRIPTS";
export const SENT_SCRIPT = "SENT_SCRIPT";
export const EDIT_SCRIPT = "EDIT_SCRIPT";
export const EDIT_SCRIPT_DETAILS = "EDIT_SCRIPT_DETAILS";
export const PRACTITIONER_ORDERS = "PRACTITIONER_ORDERS";
export const SCRIPT_INCART = "SCRIPT_INCART";
export const SCRIPT_VIEWED = "SCRIPT_VIEWED";
export const SCRIPT_ORDER_DETAILS = "SCRIPT_ORDER_DETAILS";
export const CHECKOUT_SCRIPT = "CHECKOUT_SCRIPT";
export const PATIENT_ORDERS = "PATIENT_ORDERS";
export const CLEAR_SCRIPT_DETAIL = "CLEAR_SCRIPT_DETAIL";
export const PRACTITIONER_RECOMMENDATION_LIST = "PRACTITIONER_RECOMMENDATION_LIST";
export const PRACTITIONER_RECOMMENDATION_LIST_INIT = "PRACTITIONER_RECOMMENDATION_LIST_INIT"
export const PRACTITIONER_RECOMMENDATION_LIST_FAILURE = "PRACTITIONER_RECOMMENDATION_LIST_FAILURE"
export const UPDATE_OTHER_RECOMMENDATIONS = "UPDATE_OTHER_RECOMMENDATIONS";
export const UPDATE_RECOM_REQ = "UPDATE_RECOM_REQ";
export const SCRIPT_STATUS_CHANGE = "SCRIPT_STATUS_CHANGE";
export const PATIENT_RECOMMENDATION_LIST = "PATIENT_RECOMMENDATION_LIST";
export const PATIENT_RECOMMENDATION_LIST_INIT = "PATIENT_RECOMMENDATION_LIST_INIT"
export const PATIENT_RECOMMENDATION_LIST_FAILURE = "PATIENT_RECOMMENDATION_LIST_FAILURE"
export const SCRIPT_ORDER_UPDATE = "SCRIPT_ORDER_UPDATE"
export const SEND_REMINDER = "SEND_REMINDER"
export const PRO_CREATE_SCRIPT = "PRO_CREATE_SCRIPT"
export const RE_ORDER = "RE_ORDER"
export const RECOMMENDED_PRACTITIONER_LIST = "RECOMMENDED_PRACTITIONER_LIST";
export const RECOMMENDED_PRACTITIONER_LIST_INIT = "RECOMMENDED_PRACTITIONER_LIST_INIT"
export const RECOMMENDED_PRACTITIONER_LIST_FAILURE = "RECOMMENDED_PRACTITIONER_LIST_FAILURE"
export const CHECH_PATIENT_INCART = "CHECH_PATIENT_INCART"
export const RECOMMENDED_EDIT_SCRIPT = "RECOMMENDED_EDIT_SCRIPT";
export const SAVE_HCP_SUPPLEMENTS = "SAVE_HCP_SUPPLEMENTS"


/* patient */

export const GET_PATIENT_START = "GET_PATIENT_START";
export const GET_PATIENT_SUCCESS = "GET_PATIENT_SUCCESS";
export const GET_PATIENT_ERROR = "GET_PATIENT_ERROR";
export const GET_PATIENT_DETAIL = "GET_PATIENT_DETAIL";
export const CLEAR_PATIENT_DETAIL = "CLEAR_PATIENT_DETAIL";
export const ADDRESS_REQUEST = "ADDRESS_REQUEST";
export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
export const CLEAR_PRACTICE_ADDRESS = "CLEAR_PRACTICE_ADDRESS";
export const ADDRESS_FILLED_STATE = "ADDRESS_FILLED_STATE";
export const GET_PG3_PATIENT_DETAIL = "GET_PG3_PATIENT_DETAIL";
export const RECOMMENDED_PATIENT_LIST = "RECOMMENDED_PATIENT_LIST";
export const RECOMMENDED_PATIENT_LIST_INIT = "RECOMMENDED_PATIENT_LIST_INIT"
export const RECOMMENDED_PATIENT_LIST_FAILURE = "RECOMMENDED_PATIENT_LIST_FAILURE"

/* Shipping */
export const GET_SHIPPING_METHODS = "GET_SHIPPING_METHODS";
export const GET_STATES = "GET_STATES";
export const GET_ORDER_DATA = "GET_ORDER_DATA";
export const GET_SCRIPT_DATA = "GET_SCRIPT_DATA";
export const UPDATE_SHIPPING_ADDRESS = "UPDATE_SHIPPING_ADDRESS";
export const UPDATE_SHIPPING_METHOD = "UPDATE_SHIPPING_METHOD";
export const GET_ADDRESS_TYPES = "GET_ADDRESS_TYPES";
export const UPDATE_ORDER_STATE = "UPDATE_ORDER_STATE";
export const SELECTED_RETURN_SUPPLEMENT = "SELECTED_RETURN_SUPPLEMENT"
