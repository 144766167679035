import { RSAA } from "redux-api-middleware";
// import { withAuth } from '../reducers'
// import _ from 'lodash';
import { setRialtoApiUrl } from "../components/utilities/utility";

const client_name = localStorage.getItem("client");
const BASE_URL = setRialtoApiUrl(client_name);

export const PAYMENT_REQUEST = "@@jwt/PAYMENT_REQUEST";
export const PAYMENT_SUCCESS = "@@jwt/PAYMENT_SUCCESS";
export const PAYMENT_FAILURE = "@@jwt/PAYMENT_FAILURE";

export const PAYMENTTOSOLIDUS_REQUEST = "@@jwt/PAYMENTTOSOLIDUS_REQUEST";
export const PAYMENTTOSOLIDUS_SUCCESS = "@@jwt/PAYMENTTOSOLIDUS_SUCCESS";
export const PAYMENTTOSOLIDUS_FAILURE = "@@jwt/PAYMENTTOSOLIDUS_FAILURE";

export const CONFIRM_PAYMENT_REQUEST = "@@jwt/CONFIRM_PAYMENT_REQUEST";
export const CONFIRM_PAYMENT_SUCCESS = "@@jwt/CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAILURE = "@@jwt/CONFIRM_PAYMENT_FAILURE";

export const ORDERSTATE_REQUEST = "@@jwt/ORDERSTATE_REQUEST";
export const ORDERSTATE_SUCCESS = "@@jwt/ORDERSTATE_SUCCESS";
export const ORDERSTATE_FAILURE = "@@jwt/ORDERSTATE_FAILURE";

export const PAYMENTINTENT_REQUEST = "@@jwt/PAYMENTINTENT_REQUEST";
export const PAYMENTINTENT_SUCCESS = "@@jwt/PAYMENTINTENT_SUCCESS";
export const PAYMENTINTENT_FAILURE = "@@jwt/PAYMENTINTENT_FAILURE";

export const STRIPE_PAYMENT_REQUEST = "@@jwt/STRIPE_PAYMENT_REQUEST";
export const STRIPE_PAYMENT_SUCCESS = "@@jwt/STRIPE_PAYMENT_SUCCESS";
export const STRIPE_PAYMENT_FAILURE = "@@jwt/STRIPE_PAYMENT_FAILURE";

export const SETUPINTENT_REQUEST = "@@jwt/SETUPINTENT_REQUEST";
export const SETUPINTENT_SUCCESS = "@@jwt/SETUPINTENT_SUCCESS";
export const SETUPINTENT_FAILURE = "@@jwt/SETUPINTENT_FAILURE";

export const USEDCARD_REQUEST = "@@jwt/USEDCARD_REQUEST";
export const USEDCARD_SUCCESS = "@@jwt/USEDCARD_SUCCESS";
export const USEDCARD_FAILURE = "@@jwt/USEDCARD_FAILURE";

export const createPaymentIntent = (
  rialto_token,
  order_number,
  order_token,
  stripe_account,
  keep_tax_to_practice,
) => {
  let data = {
    keep_tax_to_practice: keep_tax_to_practice,
    stripe_account: stripe_account,
  };
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/create_payment_intent/${order_number}/`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [PAYMENTINTENT_REQUEST, PAYMENTINTENT_SUCCESS, PAYMENTINTENT_FAILURE],
    },
  };
};

export const initiatePayment = (
  rialto_token,
  order_number,
  order_token,
  card,
  stripe_account,
  keep_tax_to_practice,
) => {
  let data = {
    keep_tax_to_practice: keep_tax_to_practice,
    stripe_account: stripe_account,
    payment_method_id: card.paymentMethod.id,
    card: card,
  };
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/create_payment_intent/${order_number}/`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [
        PAYMENT_REQUEST,
        {
          type: PAYMENT_SUCCESS,
          meta: (action, state, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusText,
                data: card,
              };
            }
          },
        },
        {
          type: PAYMENT_FAILURE,
          meta: (action, state, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusText,
                data: card,
              };
            }
          },
        },
      ],
    },
  };
};

export const StripeCreatePaymentIntent = (
  rialto_token,
  order_number,
  order_token,
  setup_intent,
  customer_id,
  stripe_account,
  keep_tax_to_practice,
  cvc_token,
) => {
  let data = {
    keep_tax_to_practice: keep_tax_to_practice,
    practice_stripe_account: stripe_account,
    setup_intent: setup_intent,
    stripe_payment_method_id: setup_intent.payment_method,
    spree_payment_method_id: 1,
    order_number: order_number,
    customer: customer_id,
    cvc_token: cvc_token,
  };
  console.log("StripeCreatePaymentIntent action ******************888");
  console.log(data);

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/stripe/create_intent`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [
        STRIPE_PAYMENT_REQUEST,
        {
          type: STRIPE_PAYMENT_SUCCESS,
          meta: (action, state, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusText,
                setupIntent: setup_intent,
              };
            }
          },
        },
        {
          type: STRIPE_PAYMENT_FAILURE,
          meta: (action, state, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusText,
                setupIntent: setup_intent,
              };
            }
          },
        },
      ],
    },
    // [RSAA]: {
    //     endpoint: BASE_URL + `/rialto/api/create_payment_intent/${order_number}/`,
    //     method: 'POST',
    //     body: JSON.stringify(data),
    //     headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${rialto_token}`, 'X-Spree-Order-Token': `${order_token}` },
    // types: [
    //     PAYMENT_REQUEST,
    //     {
    //       type: PAYMENT_SUCCESS, meta: (action, state, res) => {
    //         if (res) {
    //           return {
    //             status: res.status,
    //             statusText: res.statusText,
    //             data:card
    //           }
    //         }
    //       }
    //     },
    //     {
    //       type: PAYMENT_FAILURE, meta: (action, state, res) => {
    //         if (res) {
    //           return {
    //             status: res.status,
    //             statusText: res.statusText,
    //             data:card
    //           }
    //         }
    //       }
    //     },

    // ]
    //   }
  };
};

export const pureProStripeCreatePaymentIntent = (
  rialto_token,
  order_number,
  order_token,
  setup_intent,
  customer_id,
  stripe_account,
  practitioner_stripe_account,
  keep_tax_to_practice,
  cvc_token,
) => {
  let data = {
    keep_tax_to_practice: keep_tax_to_practice,
    practice_stripe_account: stripe_account,
    practitioner_stripe_account: practitioner_stripe_account,
    setup_intent: setup_intent,
    stripe_payment_method_id: setup_intent.payment_method,
    spree_payment_method_id: 1,
    order_number: order_number,
    customer: customer_id,
    cvc_token: cvc_token,
  };
  console.log("StripeCreatePaymentIntent action ******************888");
  console.log(data);

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/stripe/create_intent`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [
        STRIPE_PAYMENT_REQUEST,
        {
          type: STRIPE_PAYMENT_SUCCESS,
          meta: (action, state, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusText,
                setupIntent: setup_intent,
                cvcToken: cvc_token,
              };
            }
          },
        },
        {
          type: STRIPE_PAYMENT_FAILURE,
          meta: (action, state, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusText,
                setupIntent: setup_intent,
                cvcToken: cvc_token,
              };
            }
          },
        },
      ],
    },
  };
};

export const paymentToSolidus = (
  rialto_token,
  order_number,
  order_token,
  paid_detail,
) => {
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/orders/${order_number}/payments/`,
      method: "POST",
      body: JSON.stringify(paid_detail),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [
        PAYMENTTOSOLIDUS_REQUEST,
        PAYMENTTOSOLIDUS_SUCCESS,
        PAYMENTTOSOLIDUS_FAILURE,
      ],
    },
  };
};

export const updatePaymentToSolidus = (
  rialto_token,
  order_number,
  id,
  order_token,
  paid_detail,
) => {
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/orders/${order_number}/payments/${id}`,
      method: "PATCH",
      body: JSON.stringify(paid_detail),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [
        PAYMENTTOSOLIDUS_REQUEST,
        PAYMENTTOSOLIDUS_SUCCESS,
        PAYMENTTOSOLIDUS_FAILURE,
      ],
    },
  };
};

export const confirmPaymentIntent = (
  rialto_token,
  order_number,
  order_token,
  payment_intent,
  stripe_account,
  is_practice_self_order,
) => {
  console.log("confirmPaymentIntent********");
  console.log(payment_intent);
  let data = {
    is_practice_self_order: is_practice_self_order,
    payment_intent_id: payment_intent.payment_intent_id,
    client_secret: payment_intent.client_secret,
    stripe_account: stripe_account,
  };
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/confirm_payment_intent/${order_number}/`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [
        CONFIRM_PAYMENT_REQUEST,
        CONFIRM_PAYMENT_SUCCESS,
        CONFIRM_PAYMENT_FAILURE,
      ],
    },
  };
};

export const StripeConfirmPaymentIntent = (
  rialto_token,
  order_number,
  order_token,
  payment_intent_id,
  client_secret,
  stripe_account,
  keep_tax_to_practice,
  cvc_token,
) => {
  console.log("StripeConfirmPaymentIntent -------------, params");
  let data = {
    keep_tax_to_practice: keep_tax_to_practice,
    spree_payment_method_id: 1,
    practice_stripe_account: stripe_account,
    stripe_payment_intent_id: payment_intent_id,
    order_number: order_number,
    rialto_token: rialto_token,
    client_secret: client_secret,
    cvc_token: cvc_token,
  };

  console.log(data);

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/stripe/create_intent`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [
        CONFIRM_PAYMENT_REQUEST,
        CONFIRM_PAYMENT_SUCCESS,
        CONFIRM_PAYMENT_FAILURE,
      ],
    },
  };
};
export const orderComplete = (rialto_token, order_number, order_token) => {
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/checkouts/${order_number}/complete/`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
        "X-Spree-Order-Token": `${order_token}`,
      },
      types: [ORDERSTATE_REQUEST, ORDERSTATE_SUCCESS, ORDERSTATE_FAILURE],
    },
  };
};

export const saveCard = (
  rialto_token,
  stripe_account,
  shipping,
  practitioner_id,
  practice_own_tax,
  patient_id,
  tenant_id,
) => {
  let data = {
    stripe_account: stripe_account,
    shipping: shipping,
    practitioner_id: practitioner_id,
    practice_own_tax: practice_own_tax,
    prescribed_to_user_id: patient_id,
    tenant_id: tenant_id,
    rialto_token: rialto_token,
  };
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/setup_intent`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
      },
      types: [SETUPINTENT_REQUEST, SETUPINTENT_SUCCESS, SETUPINTENT_FAILURE],
    },
  };
};

export const closeError = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLOSE_ERROR" });
  };
};

export const stripeElement = (ccelement, ccstripe) => {
  return (dispatch, getState) => {
    dispatch({
      type: "STRIPE_ELEMENT",
      ccelement: ccelement,
      ccstripe: ccstripe,
    });
  };
};

export const usedCards = (rialto_token, userId) => {
  console.log("usedCards");
  console.log(userId);
  return {
    [RSAA]: {
      endpoint:
        BASE_URL +
        `/rialto/api/user/${userId}/wallets?rialto_token=` +
        rialto_token,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
      },
      types: [USEDCARD_REQUEST, USEDCARD_SUCCESS, USEDCARD_FAILURE],
    },
  };
};

export const updateSetupIntent = (
  rialto_token,
  stripe_account,
  customer_id,
  payment_method_id,
  setup_intent_id,
) => {
  let data = {
    stripe_account: stripe_account,
    rialto_token: rialto_token,
    customer_id: customer_id,
    setup_intent_id: setup_intent_id,
    payment_method_id: payment_method_id,
  };
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/update_setup_intent`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialto_token}`,
      },
      types: [SETUPINTENT_REQUEST, SETUPINTENT_SUCCESS, SETUPINTENT_FAILURE],
    },
  };
};
