import * as actionTypes from "../constants/action-types";
import axios from "axios";
import { RSAA } from "redux-api-middleware";
import { setRialtoApiUrl } from "../components/utilities/utility";

const client_name = localStorage.getItem("client");
const BASE_URL = setRialtoApiUrl(client_name);

export const APPTOKEN_REQUEST = "@@jwt/APPTOKEN_REQUEST";
export const APPTOKEN_SUCCESS = "@@jwt/APPTOKEN_SUCCESS";
export const APPTOKEN_FAILURE = "@@jwt/APPTOKEN_FAILURE";

export const CREATE_SCRIPT_REQUEST = "@@jwt/CREATE_SCRIPT_REQUEST";
export const CREATE_SCRIPT_SUCCESS = "@@jwt/CREATE_SCRIPT_SUCCESS";
export const CREATE_SCRIPT_FAILURE = "@@jwt/CREATE_SCRIPT_FAILURE";

export const TOKEN_REQUEST = "@@jwt/TOKEN_REQUEST";
export const TOKEN_RECEIVED = "@@jwt/TOKEN_RECEIVED";
export const TOKEN_FAILURE = "@@jwt/TOKEN_FAILURE";

export const TAX_CERT_REQUEST = "TAX_CERT_REQUEST";
export const TAX_CERT_SUCCESS = "TAX_CERT_SUCCESS";
export const TAX_CERT_FAILURE = "TAX_CERT_FAILURE";

export const STRIPE_REQUEST = "@@jwt/STRIPE_REQUEST";
export const STRIPE_SUCCESS = "@@jwt/STRIPE_SUCCESS";
export const STRIPE_FAILURE = "@@jwt/STRIPE_FAILURE";

export const PURESTRIPE_SUCCESS = "@@jwt/PURESTRIPE_SUCCESS";
export const PURESTRIPE_FAILURE = "@@jwt/PURESTRIPE_FAILURE";

export const UPLOAD_TAX_REQUEST = "UPLOAD_TAX_REQUEST";
export const UPLOAD_TAX_SUCCESS = "UPLOAD_TAX_SUCCESS";
export const UPLOAD_TAX_FAILURE = "UPLOAD_TAX_FAILURE";

export const CANCEL_REVOKE_STRIPE_REQUEST = "CANCEL_REVOKE_STRIPE_REQUEST";
export const CANCEL_REVOKE_STRIPE_SUCCESS = "CANCEL_REVOKE_STRIPE_SUCCESS";
export const CANCEL_REVOKE_STRIPE_FAILURE = "CANCEL_REVOKE_STRIPE_FAILURE";

export const GET_MANAGEMENT_DATA_REQUEST = "GET_MANAGEMENT_DATA_REQUEST";
export const GET_MANAGEMENT_DATA_SUCCESS = "GET_MANAGEMENT_DATA_SUCCESS";
export const GET_MANAGEMENT_DATA_FAILURE = "GET_MANAGEMENT_DATA_FAILURE";

export const STRIPE_AUTO_CONNECT_REQUEST = "STRIPE_AUTO_CONNECT_REQUEST";
export const STRIPE_AUTO_CONNECT_SUCCESS = "STRIPE_AUTO_CONNECT_SUCCESS";
export const STRIPE_AUTO_CONNECT_FAILURE = "STRIPE_AUTO_CONNECT_FAILURE";

export const REVOKE_STRIPE_REQUEST = "REVOKE_STRIPE_REQUEST";
export const REVOKE_STRIPE_SUCCESS = "REVOKE_STRIPE_SUCCESS";
export const REVOKE_STRIPE_FAILURE = "REVOKE_STRIPE_FAILURE";

export const UPDATE_DISCOUNT_REQUEST = "UPDATE_DISCOUNT_REQUEST";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_FAILURE = "UPDATE_DISCOUNT_FAILURE";

export const PAN_REQUEST = "PAN_REQUEST";
export const PAN_SUCCESS = "PAN_SUCCESS";
export const PAN_FAILURE = "PAN_FAILURE";

export const HANLDE_ACC_REQUEST = "HANLDE_ACC_REQUEST";
export const HANLDE_ACC_SUCCESS = "HANLDE_ACC_SUCCESS";
export const HANLDE_ACC_FAILURE = "HANLDE_ACC_FAILURE";

export const UPDATE_STRIPE_ACCOUNT_REQUEST = "UPDATE_STRIPE_ACCOUNT_REQUEST";
export const UPDATE_STRIPE_ACCOUNT_SUCCESS = "UPDATE_STRIPE_ACCOUNT_SUCCESS";
export const UPDATE_STRIPE_ACCOUNT_FAILURE = "UPDATE_STRIPE_ACCOUNT_FAILURE";

export const GET_ACC_SESSION_REQUEST = "GET_ACC_SESSION_REQUEST";
export const GET_ACC_SESSION_SUCCESS = "GET_ACC_SESSION_SUCCESS";
export const GET_ACC_SESSION_FAILURE = "GET_ACC_SESSION_FAILURE";




// export const getRialtoApptokenStart = () => {
//   return {
//     type: actionTypes.RIALTO_AUTH_START
//   }
// }

// export const getRialtoApptokenSuccess = (token, userType, tenentId, practitionerId, patientId) => {
//   localStorage.setItem("railto-token", token)
//   return {
//     type: actionTypes.RIALTO_AUTH_SUCCESS,
//     token: token,
//     userType: userType,
//     tenentId: tenentId,
//     practitionerId: practitionerId,
//     patientId: patientId
//   }

// }

// export const getRialtoApptokenError = (error) => {
//   return {
//     type: actionTypes.RIALTO_AUTH_ERROR,
//     error: error
//   }
// }

export const getRialtoAppToken = (
  userEmail,
  userType,
  tenentId,
  practitionerId,
  patientId,
  userToken,
  subdomain,
  firstName,
  lastName,
  phone,
  appToken,
  browseCatalog,
  parentUrl,
  language,
  selfOrder,
  isSendScript,
  profileId,
  userInfoTk
) => {
 
  var user_id = userType === "Patient" ? patientId : practitionerId

  let params = {
    email: userEmail,
    user_type: userType,
    tenant_id: tenentId,
    user_id: user_id,
    first_name: firstName,
    last_name: lastName,
    phone: phone,
    rialto_token: appToken,
    user_info_tk: userInfoTk
  };
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/auth/login/`,
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      types: [
        APPTOKEN_REQUEST,
        {
          type: APPTOKEN_SUCCESS,
          meta: (action, state, res) => {
            if (res) {
              console.log(res, "base url----------------");
              return {
                status: res.status,
                statusText: res.statusText,
                userType: userType,
                tenentId: tenentId,
                practitionerId: practitionerId,
                patientId: patientId,
                userEmail: userEmail,
                userToken: userToken,
                subdomain: subdomain,
                practitionerName: firstName + lastName,
                appToken: appToken,
                browseCatalog: browseCatalog === "true" ? true : false,
                parentUrl: parentUrl,
                language: language,
                selfOrder: selfOrder,
                isSendScript: userType === "User",
                profileId: profileId,
                userInfoTk: userInfoTk
              };
            }
          },
        },
        APPTOKEN_FAILURE,
      ],
    },
  };
};

export const getPatientRialtoAppToken = (
  userToken,
  userType,
  tenentId,
  patientId,
  subDomain
) => {
  return (dispatch, getState) => {
    dispatch({
      type: "PATIENTAPPTOKEN",
      user_token: userToken,
      user_type: userType,
      tenent_id: tenentId,
      patient_id: patientId,
      subdomain: subDomain,
      practitioner_id: null,
      stripe_account: null,
    });
  };
};

export const getPureProStripeAccount = (rialtoToken, tenantId, scriptId) => {
  return {
    [RSAA]: {
      endpoint:
        BASE_URL +
        `/rialto/api/practitioner_stripe?rialto_token=${rialtoToken}&tenant_id=${tenantId}&script_id=${scriptId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ClientCall: "true",
        Authorization: `Bearer ${rialtoToken}`,
      },
      types: [STRIPE_REQUEST, PURESTRIPE_SUCCESS, PURESTRIPE_FAILURE],
    },
  };
};

export const getStripeAccount = (
  userToken,
  userType,
  practitionerId,
  subDomain,
  clientBaseUrl
) => {
  console.log("getStripeAccount***********");
  var url = process.env.REACT_APP_HTTP + subDomain + "." + clientBaseUrl;
  console.log(url, "stripe url++++++++++++++++++++++++++");
  var type = userType;
  if (type != "Patient") {
    type = "User";
  }

  return {
    [RSAA]: {
      endpoint: url + `/api/rialto/practitioner_stripe?id=${practitionerId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Token": `${userToken}`,
        "User-Type": `${type}`,
      },
      types: [STRIPE_REQUEST, STRIPE_SUCCESS, STRIPE_FAILURE],
    },
  };
};

export const createOrderScript = (
  rialtoToken,
  patientId,
  tenantId,
  practitionerId,
  scriptName
) => {
  var params = {
    tenant_id: tenantId,
    prescribed_by_user_id: practitionerId,
    prescribed_to_user_id: patientId,
    script_name: scriptName,
    prescribed_to_user_type: "Patient",
  };

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/create_script/`,
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${rialtoToken}`,
      },
      types: [
        CREATE_SCRIPT_REQUEST,
        CREATE_SCRIPT_SUCCESS,
        CREATE_SCRIPT_FAILURE,
      ],
    },
  };
};

export const refreshAccessToken = (token) => {
  return {
    [RSAA]: {
      endpoint: BASE_URL + "/api/refresh_token/",
      method: "POST",
      body: JSON.stringify({ token: token }),
      headers: { "Content-Type": "application/json" },
      types: [TOKEN_REQUEST, TOKEN_RECEIVED, TOKEN_FAILURE],
    },
  };
};

export function browsCatlgStatChng() {
  return function (dispatch) {
    dispatch({ type: actionTypes.BROW_CATAL_STATE_CHANGE });
  };
}

export const fetchTaxCertificate = (
  token,
  currentPracticeID,
  clientAppToken
) => {
  return {
    [RSAA]: {
      endpoint:
        BASE_URL +
        `/rialto/api/fetch_tax_certificates?app_token=${clientAppToken}&tenant_id=${currentPracticeID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Current-Practice": currentPracticeID,
      },
      types: [TAX_CERT_REQUEST, TAX_CERT_SUCCESS, TAX_CERT_FAILURE],
    },
  };
};

export const updateDiscount = (
  token,
  currentPracticeID,
  clientAppToken,
  discount,
  practitioner_id
) => {
  const data = {
    discount: discount,
    tenant_id: currentPracticeID,
    app_token: clientAppToken,
    practitioner_id: practitioner_id,
  };
  return {
    [RSAA]: {
      endpoint: `${BASE_URL}/rialto/api/update_tenant_discount_vd`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Current-Practice": currentPracticeID,
      },
      types: [
        UPDATE_DISCOUNT_REQUEST,
        UPDATE_DISCOUNT_SUCCESS,
        UPDATE_DISCOUNT_FAILURE,
      ],
    },
  };
};

export const submitTaxCertificate = (
  token,  
  data
) => {
  return {
    [RSAA]: {
      endpoint: `${BASE_URL}/rialto/api/upload_tax_certificate`,
      body: data,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,        
      },
      types: [UPLOAD_TAX_REQUEST, UPLOAD_TAX_SUCCESS, UPLOAD_TAX_FAILURE],
    },
  };
};

export const getManagementData = (
  token,
  currentPracticeID,
  clientAppToken,
  practitionerId
) => {
  return {
    [RSAA]: {
      endpoint: `${BASE_URL}/rialto/api/rialto_management_data?app_token=${clientAppToken}&tenant_id=${currentPracticeID}&practitioner_id=${practitionerId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Current-Practice": currentPracticeID,
      },
      types: [
        GET_MANAGEMENT_DATA_REQUEST,
        GET_MANAGEMENT_DATA_SUCCESS,
        GET_MANAGEMENT_DATA_FAILURE,
      ],
    },
  };
};

export const updateStripeAccountAPI = (
  token,
  currentPracticeID,  
  data,  
) => {   
  return {
    [RSAA]: {
      endpoint: `${BASE_URL}/rialto/api/update_stripe_account`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Current-Practice": currentPracticeID,
      },
      types: [
        UPDATE_STRIPE_ACCOUNT_REQUEST,
        UPDATE_STRIPE_ACCOUNT_SUCCESS,
        UPDATE_STRIPE_ACCOUNT_FAILURE,
      ],
    },
  };
};

export const handleAccount = (
  token,
  currentPracticeID,  
  data
) => {
  return {
    [RSAA]: {
      endpoint: `${BASE_URL}/rialto/api/practice/${currentPracticeID}/update_profit_flag`,
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Current-Practice": currentPracticeID,
      },
      types: [
        HANLDE_ACC_REQUEST,
        HANLDE_ACC_SUCCESS,
        HANLDE_ACC_FAILURE,
      ],
    },
  };
};

export const pureAccountCall = (
  email,
  password,
  website,
  token,
  tenant_id,
  app_token,
  practitioner_id
) => {
  let params = {
    email,
    password,
    website,
    app_token,
    tenant_id,
    practitioner_id
  };
  return {
    [RSAA]: {
      endpoint: `${BASE_URL}/rialto/api/check_pure_account`,
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Current-Practice": tenant_id,
      },
      types: [
        UPDATE_DISCOUNT_REQUEST,
        UPDATE_DISCOUNT_SUCCESS,
        UPDATE_DISCOUNT_FAILURE,
      ],
    },
  };
};

export const getAccountSession = (email, token) => {

  let params = {
    email: email
  };
  return {
    [RSAA]: {
      endpoint: `${BASE_URL}/rialto/api/stripe_account_session`,
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      method: "POST",
      types: [GET_ACC_SESSION_REQUEST, GET_ACC_SESSION_SUCCESS, GET_ACC_SESSION_FAILURE],
    },
  };
}


// export const updateStripeAccount = (email) => {

//   let params = {
//     email: email
//   };
//   return {
//     [RSAA]: {
//       endpoint: `${BASE_URL}/rialto/api/update_stripe_account`,
//       body: JSON.stringify(params),
//       headers: {
//         "Content-Type": "application/json",
//       },
//       method: "POST",
//       types: [GET_ACC_SESSION_REQUEST, GET_ACC_SESSION_SUCCESS, GET_ACC_SESSION_FAILURE],
//     },
//   };
// }
