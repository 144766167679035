import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import _ from "lodash";
import qs from "query-string";
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
const REACT_APP_PG3_APP_DOMAIN = process.env.REACT_APP_RIALTO_API_URL;
const obj = qs.parse(window.location.search, { decode: false });
let i18nextValue = 'en'
if (!_.isEmpty(obj) && obj.language) {
	i18nextValue = obj.language
  console.log(i18nextValue, "-----inside loop")
}
i18n
.use(Backend)
.use(initReactI18next)
.use(LanguageDetector)
.init({
		fallbackLng: false, // use et if detected lng is not available
		saveMissing: true, // send not translated keys to endpoint
		initImmediate: false,
		keySeparator: false,
		interpolation: {
		escapeValue: false
		},
		nonExplicitSupportedLngs: true,
		debug: true,
		react: {
		useSuspense: true
		},
		backend: {
		loadPath:  REACT_APP_PG3_APP_DOMAIN + '/language_text?lang=' + i18nextValue,
		crossDomain: true,
		}
});


export default i18n;