import * as actionTypes from "../constants/action-types";


const initialState = {

  lmToken: null,
  tenentId: null,
  patientId: null,
  practitionerId: null,
  userType: null,
  patientsList: [],
  patientDetail: {},
  loaded: false,
  detailLoaded: false,
  error: null,
  practitionerName: '',
  userToken: '',
  subDomain: '',
  practiceAddress: null,
  addressFilled: false,
  catalogUserDocType: null,
  countryList: [{ id: 1, name: "US" }]
}

const getPatientStart = (state, action) => {
  return Object.assign({}, state, { error: null, loaded: false })
}

const getPatientSuccess = (state, action) => {
  console.log(action.subDomain, "sub domain coming")
  return Object.assign({}, state, {
    patientsList: action.patientList,
    practitionerName: action.practitionerName,
    subDomain: action.subDomain,
    loaded: true
  })
}

const getPatientError = (state, action) => {
  return Object.assign({}, state, { error: action.error, loaded: false })
}

const getPatientDetail = (state, action) => {
  return Object.assign({}, state, { patientDetail: action.patientDetail, detailLoaded: true, subDomain: action.subDomain, userToken: action.userToken, patientId: action.patientDetail.id })
}

const clearPatientDetail = (state) => {
  return Object.assign({}, state, { patientDetail: {}, detailLoaded: false })
}

const getPracticeAddress = (state, action) => {
  localStorage.setItem("docType", action.userDocType)
  return Object.assign({}, state, { practiceAddress: action.practiceAddress, catalogUserDocType: action.userDocType, practitionerName: action.practiceAddress?.first_name + ' ' + action.practiceAddress?.last_name })
}

const clearAddressFilledState = (state, action) => {
  return Object.assign({}, state, { addressFilled: action.practiceAddressFilledState })
}

const setAddressFilledState = (state, action) => {
  console.log(action, "actions")
  return Object.assign({}, state, { addressFilled: action.practiceAddressFilledState })
}


function rootReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PATIENT_START:
      return getPatientStart(state, action)
    case actionTypes.GET_PATIENT_SUCCESS:
      return getPatientSuccess(state, action)
    case actionTypes.GET_PATIENT_ERROR:
      return getPatientError(state, action)
    case actionTypes.GET_PATIENT_DETAIL:
      return getPatientDetail(state, action)
    case actionTypes.CLEAR_PATIENT_DETAIL:
      return clearPatientDetail(state)
    case actionTypes.ADDRESS_SUCCESS:
      return getPracticeAddress(state, action)
    case actionTypes.CLEAR_PRACTICE_ADDRESS:
      return clearAddressFilledState(state, action)
    case actionTypes.ADDRESS_FILLED_STATE:
      return setAddressFilledState(state, action)
  }
  return state;
}

export default rootReducer;