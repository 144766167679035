import * as actionTypes from "../constants/action-types";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import { RSAA } from 'redux-api-middleware';
import Cookies from "js-cookie";
import { updateNewScriptId } from "./script"

import { setRialtoApiUrl } from "../components/utilities/utility";

const client_name = localStorage.getItem("client");
const BASE_URL = setRialtoApiUrl(client_name);


export const GET_SHIPPING_LIST_REQUEST = '@@jwt/GET_SHIPPING_LIST_REQUEST';
export const GET_SHIPPING_LIST_SUCCESS = '@@jwt/GET_SHIPPING_LIST_SUCCESS';
export const GET_SHIPPING_LIST_FAILURE = '@@jwt/GET_SHIPPING_LIST_FAILURE';

export const GET_HCP_STATUS_REQUEST = '@@jwt/GET_HCP_STATUS_REQUEST';
export const GET_HCP_STATUS_SUCCESS = '@@jwt/GET_HCP_STATUS_SUCCESS';
export const GET_HCP_STATUS_FAILURE = '@@jwt/GET_HCP_STATUS_FAILURE';

export const GET_HCP_STRIPE_STATUS_REQUEST = '@@jwt/GET_HCP_STRIPE_STATUS_REQUEST';
export const GET_HCP_STRIPE_STATUS_SUCCESS = '@@jwt/GET_HCP_STRIPE_STATUS_SUCCESS';
export const GET_HCP_STRIPE_STATUS_FAILURE = '@@jwt/GET_HCP_STRIPE_STATUS_FAILURE';

export function filteredSupplements(tags) {
  let r_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')
  return function (dispatch) {
    return axios
      .get(
        process.env +
        `/rialto/api/ecommerce/category_supplements_list?` +
        `tags=` +
        tags,
        { headers: { Authorization: `Bearer ${r_client_token}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.FILTER_BASED_SUPPLEMENTS,
          payload: res.data.data,
        });
      });
  };
}

export function selectedSupplementsList(supplements) {
  return function (dispatch) {
    return new Promise((res, rej) => {
      dispatch({
        type: actionTypes.SELECTED_SUPPLEMENTS,
        payload: supplements,
      });
      res();
    });
  };
}

export function recommededList(profileId, patientId, tenantId, appToken, language) {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL +
        "/rialto/api/patient_recommendation?patient_id=" +
        patientId +
        "&profile_id=" +
        profileId +
        "&tenant_id=" +
        tenantId +
        `&rialto_token=` +
        appToken +
        "&locale=" +
        `${language}`
      )
      .then((res) => {
        dispatch({
          type: actionTypes.RECOMMENDED_LIST,
          payload: res.data,
          categories: convertObj(res.data.categories),
          profileId: profileId,
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
}

export function selectingSupplements(supplement_code, selectStatus) {
  return function (dispatch) {
    return dispatch({
      type: actionTypes.ADD_MANUALL,
      payload: supplement_code,
      selectStatus: selectStatus,
    });
  };
}

export function selectAll(select_status) {
  return function (dispatch) {
    return dispatch({ type: actionTypes.SELECT_ALL, status: select_status });
  };
}

/* custamize script */

export function updateScripts(
  updated_supplements,
  scriptId,
  other_recommendations,
  discount,
  scriptName,
  prescribedByUserId,
  prescribedToUserId,
  UserType,
  patientReordered
) {
  var scripts = [];
  const r_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')
  return function (dispatch) {
    updated_supplements.map((aa) => {
      var scriptObj = {
        supplement_id: aa.product_id || aa.supplement_id,
        supplement_code: aa.product_code || aa.supplement_code,
        supplement_name: aa.product_name || aa.supplement_name,
        suggested_dose_quantity: aa.suggested_dose_quantity,
        suggested_dose_frequency: aa.suggested_dose_frequency,
        suggested_dose_other_info: aa.suggested_dose_other_info,
        quantity: aa.quantity,
        id: aa.ingredient_id,
        _destroy: aa.destroy ? aa.destroy : "",
        other_instructions: aa.instruction,
        ...(UserType == "User" && { practice_recommended: true })
      };
      return scripts.push(scriptObj);
    });

    var params = {
      script: {
        script_ingredients_attributes: scripts,
        other_recommendations: other_recommendations,
        discount: discount,
        name: scriptName,
        prescribed_by_user_id: prescribedByUserId,
        prescribed_to_user_id: prescribedToUserId,
        prescribed_to_user_type: UserType,
        patient_reordered: _.isUndefined(patientReordered) ? false : true
      },
    };
    dispatch({
      type: actionTypes.UPDATE_SCRIPT_INIT,
    });

    let aa = Object.keys(params.script).forEach((k) => {
      params.script[k] == null && delete params.script[k]
    })

    return axios
      .post(BASE_URL + `/rialto/api/update_script/` + scriptId, params, {
        headers: {
          Authorization: `Bearer ${r_client_token}`,
        },
      })
      .then((res, index) => {
        updated_supplements.forEach((pro) => {
          return Object.assign(pro, {
            ingredient_id: res.data.script_ingredients_hash[pro.product_code],
          });
        });
        let filterDeletedSup = _.filter(updated_supplements, function (o) { return !o.hasOwnProperty("destroy") });
        dispatch({
          type: actionTypes.UPDATE_SCRIPT_SUCCESS,
          supplments: filterDeletedSup,
        });
        return res.data;
      })
      .catch((error) => {
        toastr.error("error - " + error.response.data.message);
        dispatch({
          type: actionTypes.UPDATE_SCRIPT_FAILURE,
        });
      });
  };
}

/* product-list */

export function getProducts(rialtoToken, product_current_page) {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/products?page=` + product_current_page, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_PRODUCTS,
          payload: res.data.products,
          product_current_page: res.data.current_page,
        });
      })
      .catch((error) => {
       console.log(error.response.data.message);
      });
  };
}

export function fetchMoreProducts(
  rialtoToken,
  product_current_page,
  searchValue
) {
  var page_params = "page=" + product_current_page;
  var search = "";

  if (searchValue) {
    search = "q[name_cont]=" + searchValue;
  } else {
    search = page_params;
  }

  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/products?` + search, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })

      .then((res) => {
        if (searchValue) {
          dispatch({
            type: actionTypes.SEARCH_BY_NAME,
            payload: res.data.products,
            product_current_page: res.data.current_page,
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_MORE_PRODUCTS,
            payload: res.data.products,
            product_current_page: res.data.current_page,
          });
        }
      })
      .catch((error) => {

        toastr.error("error - " + error.response.data.message);
      });
  };
}

export function searchProductByName(rialtoToken, searchValue) {
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/products?q[name_cont]=` + searchValue, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SEARCH_BY_NAME,
          payload: res.data.products,
          product_current_page: res.data.current_page,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function seachProductbyTag(rialtoToken, selectedTagIds) {
  var tagIds = selectedTagIds.join();
  return function (dispatch) {
    return axios
      .get(BASE_URL + `/rialto/api/products?filter_tags=` + tagIds, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SEARCH_BY_TAG,
          payload: res.data.products,
          product_current_page: res.data.current_page,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

/*Tag-list-api */

export function getTagList() {
  return function (dispatch) {
    return axios
      .get(BASE_URL + "/rialto/api/catalog/category_list")
      .then((res) => {
        dispatch({ type: actionTypes.GET_TAGS, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function practitionerFlowStart(isPractitionerFlow) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SET_PRACTITION_FLOW,
      payload: isPractitionerFlow,
    });
  };
}

export function fromRecommendation(status) {
  return function (dispatch) {
    dispatch({ type: actionTypes.FROM_RECOMMEND, status: status });
  };
}

export function getselectedRecommendIds(supplementIds) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.GET_SELECT_RECOMMED_IDS,
      supplementIds: supplementIds,
    });
  };
}
export function clearRecommededProducts() {
  return function (dispatch) {
    dispatch({ type: actionTypes.CLEAR_RECOMMEDED, clearData: [] });
  };
}

export function clearCatalogSupplements() {
  return function (dispatch) {
    dispatch({ type: actionTypes.CLEAR_CATALOG, clearData: [] });
  };
}

export function clearAlternatives() {
  return function (dispatch) {
    dispatch({ type: actionTypes.CLEAR_ALTERNATIVE, clearData: [] });
  };
}

export function storeSmartSearchResult(results) {
  return function (dispatch) {
    return new Promise((res, rej) => {
      dispatch({
        type: actionTypes.STORE_SMART_RESULT,
        payload: results,
        loading: false,
      });
      res();
    })
  };
}

export function getvariantFromProductCode(
  product_code,
  rialtoToken,
  tenentId,
  appToken,
) {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL +
        `/rialto/api/product_variant/` +
        product_code +
        `?tenant_id=` +
        tenentId +
        `&rialto_token=` +
        appToken,
        { headers: { Authorization: `Bearer ${rialtoToken}` } }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.VARIANT_FROM_PRODUCTCODE,
          payload: res.data,
        });
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  };
}

export function getVariantForPure(product_code) {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL + `/rialto/api/product_variant/` + product_code).then((res) => {
          dispatch({
            type: actionTypes.VARIANT_FROM_PRODUCTCODE,
            payload: res.data,
          });
        })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  }
}

export function getTopSellingVariants() {
  return function (dispatch) {
    return axios
      .get(
        BASE_URL + `/rialto/api/top_selling_variants`,
      ).then((res) => {
        dispatch({
          type: actionTypes.GET_TOP_SELLING_VARIANTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  }
}

function renameProp(oldProp, newProp, { [oldProp]: old, ...others }) {
  return {
    [newProp]: old,
    ...others,
  };
}

function cnvObj(keyValue, arrValue) {
  return {
    [keyValue]: arrValue
  }
}

export function inventoryCheck(productArr, zipCode, rialtoToken, isRecommendScreen) {
  const skqObjs = isRecommendScreen ? _.map(productArr, "recommended_variant_sku") : _.map(productArr, "mfr-product-code")

  const skqIds = skqObjs.map((idObj) => {
    return isRecommendScreen ? cnvObj("skuId", idObj) : renameProp("raw", "skuId", idObj);
  });


  return function (dispatch) {
    const params = {
      postal_code: zipCode,
      items: skqIds,
    };
    return axios
      .post(BASE_URL + `/rialto/api/inventory_check`, params, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({ type: actionTypes.INVENTORY_CHECK, payload: res.data });
      })
      .catch((error) => {
        // toastr.error("error - " + error.response.data.message);
        console.log(error.response.data.message);
      });
  };
}


export function shippingInventoryCheck(productArr, zipCode, rialtoToken) {
  const skqObjs = _.map(productArr, "product_code")

  const skqIds = skqObjs.map((idObj) => {
    return cnvObj("skuId", idObj)
  });

  return function (dispatch) {
    const params = {
      postal_code: zipCode,
      items: skqIds,
    };
    return axios
      .post(BASE_URL + `/rialto/api/inventory_check`, params, {
        headers: { Authorization: `Bearer ${rialtoToken}` },
      })
      .then((res) => {
        dispatch({ type: actionTypes.INVENTORY_CHECK, payload: res.data });
      })
      .catch((error) => {
        // toastr.error("error - " + error.response.data.message);
        console.log(error.response.data.message);
      });
  };
}

// function convertObj(tags) {
//   return tags.map(tag => {
//     return { name: tag, checked: false }
//   })
//   return tags
// }

function convertObj(tags) {
  let keys = Object.keys(tags);

  var new4 = keys.map((k) => {
    return {
      [k]: tags[k].map((a) => {
        return { name: a, checked: false };
      }),
    };
  });
  let rewObj = new4.reduce(function (acc, x) {
    for (var key in x) acc[key] = x[key];
    return acc;
  }, {});
  return rewObj;
}

export function showFilterTags(tags) {
  const updateTagObjs = convertObj(tags);

  return function (dispatch) {
    dispatch({ type: actionTypes.SHOW_FILTER_TAG, payload: updateTagObjs });
  };
}

export function updateFilter(products) {
  return function (dispatch) {
    dispatch({ type: actionTypes.UPDATE_FILTER_CHANGE, payload: products });
  };
}

export function getSubStitution(
  clientAppToken,
  productCode,
  tenantId,
  outOfStockProduct
) {

  const rialto_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token')
  return function (dispatch) {
    var params = {
      product_codes: productCode,
      tenant_id: tenantId,
      rialto_token: clientAppToken,
    };

    return axios
      .post(BASE_URL + `/rialto/api/get_substitutions`, params, {
        headers: {
          Authorization: `Bearer ${rialto_client_token}`,
        },
      })
      .then((res) => {
        // const alternate = {
        //   outOfStockId: outOfStockProduct["mfr-product-code"].raw,
        //   outOfStock: outOfStockProduct,
        //   alterNate: res.data.products
        // };
        dispatch({
          type: actionTypes.GET_SUBSTITUTION,
          alternateSuggestion: res.data.results,
        });
      });
  };
}

export function selectedReturnSupplements(supplements) {
  return function (dispatch) {
    return new Promise((res, rej) => {
      dispatch({
        type: actionTypes.SELECTED_RETURN_SUPPLEMENT,
        payload: supplements,
      });
      res();
    });
  };
}

export function refundOption() {
  return function (dispatch) {
    return axios
      .get(BASE_URL + "/rialto/api/refund_reasons")
      .then((res) => {
        dispatch({ type: actionTypes.GET_REFUND_OPTION, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export function submitRefund(formData) {
  // const config = {
  //   headers: { 'content-type': 'multipart/form-data' }
  // }
  return function (dispatch) {
    return axios.post(BASE_URL + "/rialto/api/submit_refunds", formData)
  }
}

export const getShippingList = (orderId) => {
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/order/${orderId}/shipped_items`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        GET_SHIPPING_LIST_REQUEST, GET_SHIPPING_LIST_SUCCESS, GET_SHIPPING_LIST_FAILURE
      ]
    }
  }
}

export const clearShippingList = () => {
  return function (dispatch) {
    dispatch({ type: actionTypes.CLEAR_SHIPPING_LIST });
  };
}

export function savedSupplements(supplements) {
  return function (dispatch) {
    return new Promise((res, rej) => {
      dispatch({
        type: actionTypes.SAVE_SUPPLEMENTS,
        payload: supplements,
      });
      res();
    });
  };
}

export const getHCPStatus = (pure_account_number) => {
  let data = {
    account: pure_account_number
  };
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/hcp/status`,
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
      types: [
        GET_HCP_STATUS_REQUEST, GET_HCP_STATUS_SUCCESS, GET_HCP_STATUS_FAILURE
      ]
    }
  }
}

export const getHCPStripeStatus = (practitionerId, tenantId, rialtoToken, clientAppToken) => {
  var params = {
    practitioner_id: practitionerId,
    tenant_id: tenantId,
    rialto_token: clientAppToken
  };

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/hcp_stripe_status`,
      method: 'POST',
      body: JSON.stringify(params),
      headers: { Authorization: `Bearer ${rialtoToken}`  ,'Content-Type': 'application/json' },
      types: [
        GET_HCP_STRIPE_STATUS_REQUEST, GET_HCP_STRIPE_STATUS_SUCCESS, GET_HCP_STRIPE_STATUS_FAILURE
      ]
    }
  }
}