import * as shipping from '../actions/shipping.js'
import * as actionTypes from "../constants/action-types";
import _ from 'lodash';

const initialState = {
  isLoading: false,
  errors: {},
  message: null,
  address: undefined,
  states: [],
  scriptDataLoaded: false,
  scriptDetails: {},
  shippingMethodList: [],
  addressTypeList: [],
  orderData: undefined,
  orderLoaded: false,
  orderDetails: null,
  orderToken: null,
  shipmentNumber: null,
  selectedShippingMethodId: null,
  orderShipmentDetails: {},
  savedAddressList: [],
  shippingItems: {},
  invalidSkus: [],
  errorType: "",
  defaultAddress: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case shipping.SHIPPING_SUCCESS:

      return {
        ...state,
        isLoading: false,
        orderDetails: action.payload.data,
        errors: {}
      }
    case shipping.SHIPPING_FAILURE:
      return {
        ...state,
        isLoading: false,
        orderDetails: undefined,
        errors: action.payload.response
      }
    case shipping.STATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: action.payload.states,
        errors: {}
      }
    case shipping.STATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        states: [],
        errors: action.payload.response
      }
    case shipping.ORDERDETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderData: action.payload,
        orderToken: action.payload.token,
        errors: {}
      }
    case shipping.ORDERDETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        orderData: null,
        orderToken: null,
        errors: action.payload.response
      }
    case shipping.ORDERSTATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderDetails: action.payload,
        shipmentNumber: !_.isEmpty(action.payload.shipments) ? action.payload.shipments[0].number : null,
        orderToken: action.payload.token,
        errors: {}
      }
    case shipping.ORDERSTATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        orderDetails: null,
        shipmentNumber: null,
        orderToken: null,
        errors: action.payload.response.errors
      }
    case shipping.SCRIPTDATA_SUCCESS:
      return {
        ...state,
        scriptDataLoaded: true,
        scriptDetails: action.payload,
        errors: {},
        errorType: ""
      }
    case shipping.SCRIPTDATA_FAILURE:
      return {
        ...state,
        scriptDataLoaded: false,
        scriptDetails: {},
        errors: action.payload.response,
        errorType: ""
      }
    case shipping.SHIPPING_METHOD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedShippingMethodId: action.payload.shipping_methods[0].id,
        errors: {},
        errorType: ""
      }
    case shipping.SHIPPING_METHOD_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        selectedShippingMethodId: null,
        errors: action.payload.response.errors,
        errorType: ""
      }
    case shipping.SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shippingMethodList: action.payload,
        errors: {}
      }

    case shipping.SHIPPING_METHOD_FAILURE:
      return {
        ...state,
        isLoading: false,
        shippingMethodList: [],
        errors: action.payload.response
      }
    case shipping.ADDRESS_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addressTypeList: action.payload,
        errors: {}
      }
    case shipping.ADDRESS_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        addressTypeList: [],
        errors: action.payload.response
      }
    case shipping.UPDATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderShipmentDetails: action.payload.response,
        errors: {}
      }
    case shipping.UPDATE_SHIPMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        orderShipmentDetails: {},
        errors: action.payload.response
      }

    case shipping.GET_SAVED_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        savedAddressList: action.payload.address,
        errors: action.payload.response
      }

    case shipping.GET_SAVED_ADDRESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        savedAddressList: {},
        errors: action.payload.response
      }

    case shipping.UPDATE_SCRIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: {}
      }
    case shipping.SHIPPING_METHOD_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.response.errors
      }
    case shipping.SHIPPINGPRICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: {}
      }
    case shipping.SHIPPINGPRICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        invalidSkus: action.payload.response.invalid_skus,
        errors: { err: action.payload.response.message },
        errorType: action.payload.response.flag
      }

    case shipping.UPDATE_VALID_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {}
      }

    case shipping.UPDATE_VALID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: {}
      }

    case shipping.UPDATE_VALID_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.response
      }
    case shipping.EMAIL_UPDATE_SUCCESS:

      return {
        ...state,
        isLoading: false,
        orderDetails: action.payload,
        errors: {}
      }
    case shipping.EMAIL_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: "Email is not updated"
      }
    case shipping.GET_DEFAULT_ADDRESS_SUCCESS:
      console.log(action.payload, "-=-=-===-payload");
      return {
        ...state,
        defaultAddress: action.payload
      }
    case shipping.GET_DEFAULT_ADDRESS_FAILURE:
      return {
        ...state,
        defaultAddress: {},
        errors: "Default address not showed"
      }

    case 'CLOSE_ERROR':
      return { ...state, message: null, errors: {}, address: undefined }
    default:
      return state
  }
}