import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr';
import { routerReducer } from 'react-router-redux'
import auth, * as fromAuth from '../reducers/auth.js'
import payments  from '../reducers/payments.js'
import billing from '../reducers/billing.js'
import shipping from '../reducers/shipping.js'

import patients from "../reducers/patients.js"
import script from "../reducers/script.js"
import supplement from "../reducers/supplements.js";

const rialtoReducer = combineReducers({
  auth: auth,
  patients: patients,
  script: script,
  supplement: supplement,
  payments: payments,
  billing: billing,
  shipping: shipping,
  form: formReducer,
  toastr: toastrReducer,
  router: routerReducer
})

export default rialtoReducer;

export const isAuthenticated = state => fromAuth.isAuthenticated(state.auth)
export const accessToken = state => fromAuth.accessToken(state.auth)
export const isAccessTokenExpired = state => false
export const refreshToken = state => accessToken(state)
export const isRefreshTokenExpired = state => false

export function withAuth(headers={}) {
  return (state) => ({
    ...headers,
    'Authorization': `Bearer ${accessToken(state)}`
  })
}