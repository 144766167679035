import * as actionTypes from "../constants/action-types";
import * as supplements from '../actions/supplements.js'
import _ from "lodash";

const initialState = {
  topProducts: [],
  topProductLoading: true,
  topProductError: false,
  products: [],
  recommededProducts: [],
  recommededSupplements: [],
  message: "",
  tagList: [],
  allTags: [],
  loaded: false,
  tagsLoaded: false,
  checkoutId: "",
  supplements: [],
  scriptId: null,
  appToken: "",
  jwt_token: "",
  selectedSupplements: [],
  product_current_page: 1,
  pratitionerFromSupplement: false,
  fromRecommendation: false,
  smartSearchSupplements: [],
  supplementObj: {},
  resultLoad: false,
  selectedRecommedIds: [],
  searchResultLoading: true,
  inventoryObj: {},
  recommendtagsCatogories: {},
  alternatesSuggestions: [],
  alternatesList: [],
  profileId: null,
  selectedReturns: [],
  refundOptions: [],
  isLoading: false,
  shippingItems: {},
  savedSupplements: [],
  updateLoaded: false,
  hcpActiveStatus: false,
  scriptId: null,
  stripeActiveStatus: true
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ALL_TAG_LIST:
      console.log(action.payload, "payload");
      return Object.assign({}, state, {
        allTags: action.payload,
        loaded: true,
      });

    case actionTypes.FILTER_BASED_SUPPLEMENTS:
      return Object.assign({}, state, {
        supplements: action.payload,
        loaded: true,
      });

    case actionTypes.SELECTED_SUPPLEMENTS:
      return Object.assign({}, state, {
        selectedSupplements: action.payload,
        loaded: true,
      });

    case actionTypes.RECOMMENDED_LIST:
      return Object.assign({}, state, {
        recommededProducts: action.payload.suggessted_supplements,
        recommededSupplements: action.payload.suggessted_supplements,
        recommendtagsCatogories: action.categories,
        loaded: true,
        profileId: action.profileId,
      });

    case actionTypes.ADD_MANUALL:
      var checked = state.products.filter((pro) => {
        return pro.sku == action.payload;
      });
      Object.assign(checked[0], { checked: action.selectStatus });
      return Object.assign({}, state, { products: state.products });

    case actionTypes.SELECT_ALL:
      return Object.assign({}, state, { products: state.products });

    case actionTypes.UPDATE_SCRIPT_INIT:
      return Object.assign({}, state, {
        updateLoaded: true,
      });

    case actionTypes.UPDATE_SCRIPT_SUCCESS:
      return Object.assign({}, state, {
        selectedSupplements: action.supplments,
        savedSupplements: action.supplments,
        updateLoaded: false,
      });
    case actionTypes.UPDATE_SCRIPT_FAILURE:
      return Object.assign({}, state, {
        updateLoaded: true,
      });

    case actionTypes.GET_PRODUCTS:
      return Object.assign({}, state, {
        products: action.payload,
        product_current_page: action.product_current_page,
        loaded: true,
      });

    case actionTypes.GET_TAGS:
      return Object.assign({}, state, {
        tagList: action.payload,
        tagsLoaded: true,
      });

    case actionTypes.FETCH_MORE_PRODUCTS:
      return Object.assign({}, state, {
        products: [...state.products, ...action.payload],
        product_current_page: action.product_current_page,
        loaded: true,
      });

    case actionTypes.SEARCH_BY_NAME:
      return Object.assign({}, state, {
        products: action.payload,
        product_current_page: action.product_current_page,
        loaded: true,
      });

    case actionTypes.SEARCH_BY_TAG:
      return Object.assign({}, state, {
        products: action.payload,
        product_current_page: action.product_current_page,
        loaded: true,
      });

    case actionTypes.SET_PRACTITION_FLOW:
      return Object.assign({}, state, {
        pratitionerFromSupplement: action.payload,
      });

    case actionTypes.FROM_RECOMMEND:
      return Object.assign({}, state, { fromRecommendation: action.status });

    case actionTypes.GET_SELECT_RECOMMED_IDS:
      return Object.assign({}, state, {
        selectedRecommedIds: action.supplementIds,
      });

    case actionTypes.CLEAR_RECOMMEDED:
      return Object.assign({}, state, { recommededProducts: action.clearData });

    case actionTypes.CLEAR_CATALOG:
      return Object.assign({}, state, {
        products: action.clearData,
        alternatesSuggestions: action.clearData,
        alternatesList: action.clearData,
      });
    case actionTypes.CLEAR_ALTERNATIVE:
      return Object.assign({}, state, {
        alternatesSuggestions: [],
        alternatesList: [],
      });

    case actionTypes.STORE_SMART_RESULT:
      return Object.assign({}, state, {
        smartSearchSupplements: action.payload,
        resultLoad: true,
      });

    case actionTypes.VARIANT_FROM_PRODUCTCODE:
      return Object.assign({}, state, { supplementObj: action.payload });

    case actionTypes.INVENTORY_CHECK:
      return Object.assign({}, state, { inventoryObj: action.payload });

    case actionTypes.SHOW_FILTER_TAG:
      return Object.assign({}, state, { allTags: action.payload });

    case actionTypes.UPDATE_FILTER_CHANGE:
      return Object.assign({}, state, { recommededProducts: action.payload });

    case actionTypes.GET_SUBSTITUTION:
      const alternates = _.map(
        action.alternateSuggestion,
        "alternate_skus"
      ).flat(Infinity);

      _.map(alternates, function (element) {
        _.extend(element, { alternate_supplement: true });
      });

      return Object.assign({}, state, {
        alternatesSuggestions: action.alternateSuggestion,
        alternatesList: alternates,
      });

    case actionTypes.SELECTED_RETURN_SUPPLEMENT:
      return {
        ...state,
        selectedReturns: action.payload
      }
    case actionTypes.GET_REFUND_OPTION:
      return {
        ...state,
        refundOptions: action.payload
      }
    case supplements.GET_SHIPPING_LIST_REQUEST:
      return {
        ...state,
        isLoading: false,
        errors: {}
      }
    case supplements.GET_SHIPPING_LIST_SUCCESS:
      let update_arr = action.payload.line_items.map(obj => ({ ...obj, base_qty: obj.quantity }))
      return {
        ...state,
        isLoading: true,
        shippingItems: Object.assign(action.payload, { line_items: [...update_arr] })
      }
    case supplements.GET_HCP_STATUS_REQUEST:
      return {
        ...state,
        isLoading: false,
        hcpActiveStatus: false,
        errors: {}
      }
    case supplements.GET_HCP_STATUS_SUCCESS:
      console.log("response------------", action.payload)
      return {
        ...state,
        isLoading: true,
        hcpActiveStatus: action.payload.active
      }
    case actionTypes.CLEAR_SHIPPING_LIST:
      return {
        ...state,
        shippingItems: {}
      }
    case supplements.GET_SHIPPING_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.response
      }
    case actionTypes.GET_TOP_SELLING_VARIANTS_REQUEST:
      return {
        ...state,
        topProductLoading: true,
        topProductError: false
      }
    case actionTypes.GET_TOP_SELLING_VARIANTS_SUCCESS:
      return {
        ...state,
        topProductLoading: false,
        topProducts: action.payload.data
      }
    case actionTypes.GET_TOP_SELLING_VARIANTS_FAILURE:
      return {
        ...state,
        topProductLoading: false,
        topProductError: false,
      }
    case actionTypes.SAVE_SUPPLEMENTS:
      return Object.assign({}, state, {
        savedSupplements: action.payload,
        loaded: true,
      });
    case supplements.GET_HCP_STRIPE_STATUS_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
      })
    case supplements.GET_HCP_STRIPE_STATUS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        stripeActiveStatus: action.payload.is_stripe_active
      })
    case supplements.GET_HCP_STRIPE_STATUS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        stripeActiveStatus: false
      })

    default:
      return state;
  }
}

export default rootReducer;
