import storage from 'redux-persist/es/storage'
import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createFilter } from 'redux-persist-transform-filter';
import { persistReducer, persistStore } from 'redux-persist'
import { routerMiddleware } from 'react-router-redux'
import BlockUi from 'react-block-ui';
import ReduxBlockUi from 'react-block-ui/lib/redux';
import reduxMiddleware from 'react-block-ui/lib/reduxMiddleware';
import apiMiddleware from '../middleware';
import rialtoReducer from '../reducers'
import logger from 'redux-logger';

export default (history) => {
  const persistedFilter = createFilter(
    'payments', [],
    'billing', [],
    'shipping', [],
    'script', [],
  );

  const reducer = persistReducer(
    {
      timeout: null,
      key: process.env.REACT_APP_STORE_KEY,
      storage: storage,
      whitelist: ['payments', 'billing', 'shipping'],
      transforms: [persistedFilter]
    },
    rialtoReducer
  )

  let middleware = [apiMiddleware, thunk, routerMiddleware(history)];
		if (process.env.REACT_APP_NODE_ENV === "development") {
			middleware = [...middleware, logger, reduxMiddleware];
		} else {
			middleware = [...middleware, reduxMiddleware];
		}

  const store = createStore(
    reducer, {},
    applyMiddleware(...middleware)
  )

  persistStore(store)

  return store
}

const persistedFilter = createFilter(
  'payments', [],
  'billing', [],
  'shipping', [],
  'script', [],
);

var reducer = persistReducer(
  {
    timeout: null,
    key: process.env.REACT_APP_STORE_KEY,
    storage: storage,
    whitelist: ['payments', 'billing', 'shipping'],
    transforms: [persistedFilter]
  },
  rialtoReducer
)

export const store1 = createStore(reducer, applyMiddleware(thunk));