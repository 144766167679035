import React, { Component } from "react";

const Loading = () => {
  return (
    <div style={style.loadingStyle}>
      Loading....
    </div>
  );
};

export default Loading

const style = {

  loadingStyle: {
    textAlign: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "50%",
    fontSize: 21,
  }

}