import { isRSAA, apiMiddleware } from 'redux-api-middleware';

import { TOKEN_RECEIVED, refreshAccessToken } from '../actions/auth.js'
import { refreshToken, isAccessTokenExpired, isRefreshTokenExpired } from '../reducers'


export function createApiMiddleware() {
  let postponedRSAAs = []

  return ({ dispatch, getState }) => {
    const rsaaMiddleware = apiMiddleware({dispatch, getState})

    return (next) => (action) => {
      const nextCheckPostoned = (nextAction) => {
          // Run postponed actions after token refresh
          if (nextAction.type === TOKEN_RECEIVED) {
            next(nextAction);
            while (postponedRSAAs.length > 0) {
              // .shift() removes from front preserving FIFO
              const postponed = postponedRSAAs.shift();
              rsaaMiddleware(next)(postponed)
            }
          } else {
            next(nextAction)
          }
      }

      if(isRSAA(action)) {
        const state = getState()
        let token = refreshToken(state)
        const endpoint = action["@@redux-api-middleware/RSAA"]["endpoint"]
        if(isAccessTokenExpired(state) && endpoint && endpoint.includes("/api/auth_token/")){
          localStorage.removeItem('persist:' + process.env.REACT_APP_STORE_KEY)
          token = null
        }
        
        if(token && (isRefreshTokenExpired(state)) ) {
            postponedRSAAs.push(action)
          if(postponedRSAAs.length > 0) {                   
            const action = refreshAccessToken(token)
            return rsaaMiddleware(nextCheckPostoned)(action)
          } else { // else statement probably unnecessary
            return
          }
        }

        return rsaaMiddleware(next)(action);
      }
      return next(action);
    }
  }
}

export default createApiMiddleware();
