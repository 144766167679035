import * as payment from '../actions/payments.js'
import _ from 'lodash';

const initialState = {
  isLoading: false,
  errors: {},
  message: null,
  clientSecret: null,
  customerId: null,
  setupIntent: null,
  paymentMethodId: null,
  paymentIntent: undefined,
  cvcToken: null,
  element: null,
  stripe: null,
  paidSource: null,
  orderDetail: null,
  orderToken: null,
  previousCards: null,
}

export default (state=initialState, action) => {
  switch(action.type) {
    case payment.PAYMENTINTENT_SUCCESS:     
      return {
        ...state,
        isLoading: false,        
        clientSecret: action.payload.client_secret,
        errors: {}
      }
    case payment.PAYMENTINTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        clientSecret: null,
        errors: action.payload.response
      }  
    case payment.PAYMENT_SUCCESS: 
      let payment_intent = action.payload
      console.log("payment success************")
      console.log(action)
      console.log(payment_intent)
      // if(action.payload.data){
      //   payment_intent = action.payload.data
      // }else
      // {
      //   payment_intent = action.meta.data
      // }
      return {
        ...state,
        isLoading: false,        
        paymentIntent: payment_intent,
        paymentMethodId: action.payload.payment_method_id,
        errors: {}
      }
    case payment.PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        paymentMethodId: null,
        paymentIntent: action.meta.data,
        errors: action.payload.response
      }     
    case payment.STRIPE_PAYMENT_SUCCESS:       
      console.log("STRIPE_PAYMENT_SUCCESS************")
      console.log(action)      
      return {
         ...state,
        isLoading: false,        
        paymentIntent: action.payload.stripe_payment_intent_id,
        paymentMethod: action.meta.setupIntent.payment_method,
        cvcToken: action.meta.cvcToken,
        errors: {}
      }
    case payment.STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        setupIntent: action.meta.setupIntent,
        paymentMethod: action.meta.setupIntent.payment_method,
        cvcToken: action.meta.cvcToken,
        errors: action.payload.response
      }  
    case payment.PAYMENTTOSOLIDUS_SUCCESS: 
      console.log("PAYMENTTOSOLIDUS_SUCCESS*********", action)
      return {
        ...state,
        isLoading: false,
        paidSource: action.payload.data,        
        errors: {}
      }
    case payment.PAYMENTTOSOLIDUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        paidSource: undefined,
        errors: action.payload.response
      }
    case payment.CONFIRM_PAYMENT_SUCCESS: 
      return {
        ...state,
        isLoading: false,          
        errors: {}
      }
    case payment.CONFIRM_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,        
        errors: action.payload.response.error
      }    
    case payment.ORDERSTATE_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        orderDetail: action.payload,        
        orderToken: action.payload.token,        
        errors: {}
      }
    case payment.ORDERSTATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.response.errors
      }
    case payment.SETUPINTENT_SUCCESS:   
      console.log("success---------", action)  
      return {
        ...state,
        isLoading: false,        
        clientSecret: action.payload.client_secret,
        customerId: action.payload.customer_id,
        setupIntent: action.payload.setup_intent,
      }
    case payment.SETUPINTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        clientSecret: null,
        customerId: null,
        setupIntent: null,
        errors: action.payload.response
      }  
    case payment.USEDCARD_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        previousCards: action.payload,            
        errors: {}
      }
    case payment.USEDCARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        previousCards: null,
        errors: action.payload.response.errors
      }
    case 'STRIPE_ELEMENT':
      return {
        ...state,
        element: action.ccelement,
        stripe: action.ccstripe,
      }
    case 'CLOSE_ERROR':
      return {...state, message: null, errors:{}, paymentIntent: undefined}
    default:
      return state
    }
}

// export function withAuth(headers={}) {
//   return (state) => ({
//     ...headers,
//     'Authorization': `Bearer ${accessToken(state)}`
//   })
// }