
import { RSAA } from 'redux-api-middleware';
import { setRialtoApiUrl } from "../components/utilities/utility"
import _ from 'lodash';
// import { withAuth } from '../reducers';
const client_name = localStorage.getItem('client');
const BASE_URL = setRialtoApiUrl(client_name);

export const BILLING_REQUEST = '@@jwt/BILLING_REQUEST';
export const BILLING_SUCCESS = '@@jwt/BILLING_SUCCESS';
export const BILLING_FAILURE = '@@jwt/BILLING_FAILURE';

export const STATE_REQUEST = '@@jwt/STATE_REQUEST';
export const STATE_SUCCESS = '@@jwt/STATE_SUCCESS';
export const STATE_FAILURE = '@@jwt/STATE_FAILURE';

export const ORDERDETAIL_REQUEST = '@@jwt/ORDERDETAIL_REQUEST';
export const ORDERDETAIL_SUCCESS = '@@jwt/ORDERDETAIL_SUCCESS';
export const ORDERDETAIL_FAILURE = '@@jwt/ORDERDETAIL_FAILURE';

export const ORDERSTATE_REQUEST = '@@jwt/ORDERSTATE_REQUEST';
export const ORDERSTATE_SUCCESS = '@@jwt/ORDERSTATE_SUCCESS';
export const ORDERSTATE_FAILURE = '@@jwt/ORDERSTATE_FAILURE';

export const saveBillingAddress = (rialtoToken, orderToken, order_number, address) => {
  let data = { "order": address }
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/orders/${order_number}/`,
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${rialtoToken}`, 'X-Spree-Order-Token': `${orderToken}` },
      types: [
        BILLING_REQUEST, BILLING_SUCCESS, BILLING_FAILURE
      ]
    }
  }
}

export const listState = () => {

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/countries/233/states`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        STATE_REQUEST, STATE_SUCCESS, STATE_FAILURE
      ]
    }
  }
}

export const OrderDetail = (rialtoToken, order_number) => {
  console.log("OrderDetail")
  console.log(rialtoToken, order_number)
  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/orders/${order_number}/`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${rialtoToken}` },
      types: [
        ORDERDETAIL_REQUEST, ORDERDETAIL_SUCCESS, ORDERDETAIL_FAILURE
      ]
    }
  }
}

export const orderNextState = (rialto_token, order_number, order_token) => {

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/checkouts/${order_number}/next`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${rialto_token}`, 'X-Spree-Order-Token': `${order_token}` },
      types: [
        ORDERSTATE_REQUEST, ORDERSTATE_SUCCESS, ORDERSTATE_FAILURE
      ]
    }
  }
}

export const orderComplete = (rialto_token, order_number, order_token) => {

  return {
    [RSAA]: {
      endpoint: BASE_URL + `/rialto/api/checkouts/${order_number}/complete/`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${rialto_token}`, 'X-Spree-Order-Token': `${order_token}` },
      types: [
        ORDERSTATE_REQUEST, ORDERSTATE_SUCCESS, ORDERSTATE_FAILURE
      ]
    }
  }
}

export const closeError = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLOSE_ERROR" });
  }
}